import React from 'react'
import './Services.css'

function Services(props) {
  return (
    <article className="service__card">
        <img src={props.serviceImg} alt='logo service associé' />
        <span>{props.cardText}</span>
    </article>
  )
}

export default Services