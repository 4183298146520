import React, {useState} from 'react'
import './Footer.css'

function Footer() {
  
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  
  let handleClick = async (e) => {
    e.preventDefault();
    // Regex pattern for emails
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(regex)) {
      try {
        let res = await fetch("https://saizon.herokuapp.com/add-mail", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            mail: email
          }),
        });
        const data = await res.json();
        if (res.status === 200 && data.ajout === true) {
          setEmail("");
          setMessage("Enregistrement validé ! Nous vous contacterons au lancement du site !");
        } else {
          setMessage("Erreur lors de l'enregistrement !");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setMessage("Votre email n'est pas dans un format valide");
    }
  };

  return (
    <footer>
        <h3>Inscrivez-vous à notre newsletter !</h3>
        <h4> {message ? <> {message} </> : "Nous vous informerons lors du lancement officiel de Saizon, restez informés et soyez parmis les premiers à nous rejoindre :"}</h4>
        
        <form 
        id="footer__newsletter" 
        onSubmit={handleClick}>
           
            <input 
            type="email" 
            name="email" 
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
            placeholder='Votre e-mail' />
            
            <input 
            type="submit" 
            id="newsletter__btn" 
            value='Inscrivez moi !' />
        
        </form>
    </footer>
  )
}

export default Footer