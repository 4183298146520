import React from "react";
import "./Hero.css";
import heroBanner from "../../assets/herobanner.svg"

function Hero() {
	return (
		<section className='hero'>
			<div className='hero__text'>
				<h1>
					Nous facilitons la mise en relations entre <strong>candidats</strong>{" "}
					et <strong>employeurs</strong> des{" "}
					<strong>métiers saisonniers</strong>
				</h1>
				<a href='#services' className='hero__btn'>
					Découvrir nos services
				</a>
			</div>
			<img id='hero__bg' src={heroBanner} alt="Illustration employé et recruteur"/>
		</section>
	);
}

export default Hero;
