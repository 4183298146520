import "./App.css"
import Header from "./components/Header/Header"
import Hero from "./components/Hero/Hero"
import Services from "./components/Services/Services"
import Footer from "./components/Footer/Footer"

import calendar from "./assets/calendar.svg"
import gears from "./assets/gears.svg"
import idea from "./assets/idea.svg"
import speaker from "./assets/speaker.svg"
import target from "./assets/target.svg"
import hourglass from "./assets/hourglass.svg"


function App() {
	return (
		<>
			<div className='landing'>
				<Header />
				<Hero />
			</div>
			<main id='services'>
				<h2>Pour les employeurs</h2>
				<section className="services__section">
					<Services
						
						serviceImg={gears}
						cardText={[<strong>Facilitez</strong>," votre recrutement local et national en accédant à la plus grande base de données de ",<strong>saisonniers</strong>,", et augmentez votre visibilité !"]}	
					/>
					<Services
						
						serviceImg={speaker}
						cardText={["Publiez vos annonces en ",<strong>illimité</strong>,", recherchez des profils par secteur, métier, disponibilité, expérience, et proposez vos offres ", <strong> directement aux candidats</strong>,"."]}
					/>
					<Services 
						
						serviceImg={target}
						cardText={["Services ", <strong> Premium</strong>," avec CV thèque, campagne de communication, aide au recrutement, et plus encore !"]}
					/>
				</section>
				<h2>Pour les candidats</h2>
				<section className="services__section">
					<Services
						
						serviceImg={hourglass}
						cardText={["Trouvez ", <strong> rapidement</strong>, " votre emploi saisonnier en France ou à l'étranger, comparez ",<strong>facilement</strong>, " les différentes offres et gérez votre carrière"]}
					/>
					<Services
						
						serviceImg={calendar}
						cardText={["Publiez votre profil et ", <strong> plusieurs CV </strong>, " en fonction de votre recherche, candidatez en ",<strong> un clic</strong>," et gérez vos propositions d'emploi"]}
					/>
					<Services 
						
						serviceImg={idea}
						cardText={["Obtenez des ",<strong>recommendations</strong>," de vos anciens collègues et accédez à notre ",<strong>Blog</strong>," avec interviews, sondages, outils juridiques et aide au recrutement"]}
					/>
				</section>
			</main>
			<Footer />
		</>
	);
}

export default App;
