import React from 'react'
import logo from '../../assets/logo.svg'
import './Header.css'

function Header() {
  return (
    <header>
      <img src={logo} alt="Saizon logo" className='header__logo' />
    </header>
  )
}

export default Header